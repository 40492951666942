.list-item {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-size: 13px;
    justify-content: initial;
}

.list-item:hover {
    color: rgba(255, 255, 255, 0.9);
}

.list-item-debit:hover {
    background: #1890ff;
}

.list-item-credit:hover {
    background: #ff4d4f;
}

.list-item-category:hover {
    background: #40a9ff47;
}

.list-item-nonCategory:hover {
    background: #e8e8e8;
}

.info-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.info-wrapper > .content {
    position: absolute;
    width: 100%;
    height: auto;
    font-size: 15px;
    top: 48%;
    left: 0px;
    text-align: center;
}

.info-wrapper > .content > .ant-spin {
    position: absolute;
    margin-top: 10px;
}

.info-wrapper > .content span {
    width: 100%;
    float: left;
    font-weight: bold;
    color: #999593;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.info-wrapper > .content span:hover {
    color: black;
}

.info-count {
    position: relative;
    cursor: pointer;
    margin-bottom: 16px;
}

.info-count:after {
    content: '';
    position: absolute;
    background: #e8e9eb;
    width: 26px;
    height: 54px;
    bottom: -17px;
    right: -5px;
    transform: rotate(45deg);
}

.ant-statistic-content-value {
    font-size: 100%;
}

.ant-statistic-content-value-decimal {
    font-size: 100%;
}

.first-card-title-dark {
    background-color: #3B86FF;
}

.card-title-dark {
    background-color: #2c3e50;
}

.card-title-light {
    background-color: #ffffff;
}

.money-type-dark {
    color: #E1F2F9;
}

.money-type-light {
    color: #737373;
}

.card-title-dark:hover {
    background-color: #CACFD2;
}

.card-title-light:hover {
    background-color: #CACFD2;
}

.first-card-title-dark:hover {
    background-color: #CACFD2;
}

.first-card-title-light:hover {
    background-color: #CACFD2;
}

.footer-card {
    height: 150px;
}

.link:hover {
    color: #808b96;
}

.badge-dark-color {
    background: #274F6E;
}

.badge-light-color {
    background: #f37054;
}

.last-card-title-style-dark {
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
}

.last-card-title-style-light {
    color: #666666;
    font-family: Roboto;
    font-size: 16px;
}

.body-style {
    text-align: center;
    font-size: 18px;
}

.table-color {
    background-color: #2c3e50;
    color: #ffffff;
}


.anticon-caret-left {
    color: #000000;
}

.ant-statistic-content-prefix {
    float: left;
}

.ant-card:hover .ant-card-head-title .title-style {
    font-size: 17px;
}

.title-style {
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: transparent;
}
