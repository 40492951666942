@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #d9d9d95c;
  font-family: 'Roboto', sans-serif; 
}

#root{
  height: 100%;
  width: 100%;
}

.w-100      { width: 100% !important }
.w-50       { width: 50% !important }
.h-100      { height: 100% !important }
.w-h-100    { height: 100% !important; width: 100% !important; }

.fl-r       { float: right }
.fl-l       { float: left }

.text-right { text-align: right !important }
.text-left { text-align: left !important }
.text-center { text-align: center !important }

.fa { color: rgba(0, 0, 0, 0.65) }

a{
  color: inherit;
}

.cursor{
  cursor: pointer;
}

.white{
  background: #ffffff;
}

.stock{
  color: #ff0000;
}

.white:hover td{
  background: #22C39E;
}

.tableRowClick{
  background:#40a9ff;
  color: white;
}

.tableRowClick:hover td{
  background:#22C39E;
  color: #000000a6;
}

.label-default span:not(.ant-tag){
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.label-default span.ant-tag{
  font-size: 13px;
  cursor: pointer;
}

.label-default p{
  text-indent: 40px;
  display: inline-block;
  margin: 0px;
}

.form-label-default .ant-form-item-label{
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.form-label-default .ant-form-item-label p{
  text-indent: 40px;
  display: inline-block;
  margin: 0px;
}

.form-label-default.no-indent .ant-form-item-label p {
  text-indent: 0px;
}

@media(max-width: 767px){
  .form-label-default .ant-form-item-label p{
    text-indent: 5px;
  }

  .label-default p{
    text-indent: 5px;
  }
}

.form-label-default .ant-form-item-label label:after{
  display: none;
}

.form-block{
  padding: 0px 24px;
}

.form-block:first-of-type{
  padding-top: 24px;
}

.default-button{
  float: right;
  padding: 0px 15px;
}

tr.ant-table-expanded-row {
  background-color: #f8f8f8
}


.danger2{
	background-color: #f5222d;
	color: #fff;
	border-color: #ff4d4f;
}

.danger2:hover{
	background-color: #f5222dcc;
	color: #fff;
	border-color: #ff4d4f;
}

.success{
	background-color: #5cb85c;
	color: #fff;
	border-color: #5cb85c;
}
.success:hover{
	background-color: #5cb85ccc;
	color: #fff;
	border-color: #5cb85c;
}
.menu-default li{
  padding: 6px 14px
}
      
  .menu-default i{
      font-size: 12px;
  }

.table-no-border .ant-table{
  border-radius: 0px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
	border-right: 0px !important;
}

::-webkit-scrollbar-thumb{ 
  background-color: rgb(200, 200, 200);
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px
}

.ant-form-item .ant-form-item{
  margin-bottom: 0px;
}

tr.ant-table-expanded-row, tr.ant-table-expanded-row-level-1 {
  background-color: #fafafa !important;
}

.ant-form-item-children {
  position: unset !important;
}

.ant-statistic-content-value-int{
	font-size: '100px';
}

.ant-upload {
  width: 100% !important;
}

.ant-upload button {
  background: #e8e9eb3b !important;
}

.ant-upload button:hover {
  color: inherit !important;
  border-color: #d9d9d9 !important;
  background:#e8e9eb7d !important;
}

.ant-select-remove-icon svg {
  color: black;
}