.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
    margin-bottom: 5px;
}

.login-logo {
    width: 100%;
    padding: 0px 30px 20px 30px;
}