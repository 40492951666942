.ant-collapse-content {
    padding: 0px;
    overflow: visible;;
}

.ant-collapse-content-box {
    padding-bottom: 0px !important;
    border-top: 1px solid #e8e8e8;
    box-sizing: border-box;
}


.menu-element.ui-draggable-dragging {
    border: 1px solid #e8e8e8;
}

.element:hover > i {
    display: block !important;
}

.element > .ui-resizable-se {
    display: none !important;
}

.element:hover > .ui-resizable-se {
    display: block !important;
}

.image-button {
    opacity: 0.5;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
}

.image-button:hover {
    opacity: 1;
}
