.main .sider {
    height: '100vh';
    border-right: '0px';
    transition: background .3s, width .2s;
    z-index: 1;
}

.main .logo-block {
    width: 100%;
    height: auto;
}

.main .logo-block img {
    width: 100%;
    height: auto;
    display: inline-block;
}

.main .logo-block p {
    font-weight: bold;
    color: #1890ff;
    font-size: 16px;
    text-indent: 10px;
    display: inline-block;
    margin: 0px;
}

.main .header {
    background: #fff;
    height: 54px;
    border-bottom: 1px solid #f8f8f8;
    padding: 0px;
}

.menu-popover {
    width: 280px;
}

.main .header .header-avatar {
    background-color: #445463;
}

.main .header .header-avatar:hover {
    background-color: #808b96;
}

.main .sider-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.main .side-trigger {
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 17px;
    cursor: pointer;
}

.main .sider-footer {
    position: absolute;
    bottom: 0px;
    border-color: #001629;
    width: 100%;
    padding: 14px 16px;
    font-size: 12px;
    color: #666;
}

.main .switch-theme {
    float: left;
    margin-top: 2px;
}

.main .sider-footer i {
    font-size: 14px;
}

.main .content-breadcrumb {
    font-size: 14px;
    margin-bottom: 20px;
    text-indent: 2px;
}

.main .dark-button-color {
    background-color: #445463;
}

.main .light-button-color {
    background-color: transparent;
}

.main .dark-button-color:hover {
    background-color: #3B86FF;
}

.dark-layout .ant-card-head, .dark-layout .ant-modal-header {
    background-color: #1B2F41;
}

.dark-layout .ant-card-head, .dark-layout .ant-modal-header .ant-modal-title {
    color: #ffffff;
}

.light-layout .ant-card-head, .dark-layout .ant-modal-header .ant-modal-title {
    color: #ffffff;
}

/* .light-layout .ant-modal-header{
    background-color: #1B2F41;
    color: #ffffff;
}
*/

.dark-layout .ant-alert-close-icon .anticon-close {
    color: #000000;
}

.light-layout .ant-alert-close-icon .anticon-close {
    color: #000000;
}

.dark-layout .anticon-close {
    color: #fff;
}

.light-layout .anticon-close {
    /* color: #ffffff; */
}

.light-layout .anticon-close {
    /* color: #ffffff; */
}

.dark-layout .ant-modal-header {
    background-color: #1B2F41;
    color: #ffffff;
}

.light-layout .ant-modal-header {
    background-color: #f37054;
    color: #ffffff;
}

.light-layout .ant-table-thead > tr > th {
    background-color: #f37054;
    color: #ffffff;
}

.dark-layout .ant-table-thead > tr > th {
    background-color: #1B2F41;
    color: #ffffff;
}

.light-layout .ant-card-head {
    background-color: #f37054;
    color: #ffffff;
}

.main .row-color {
    background-color: #1B2F41;
}

.main .row-colorr {
    background-color: #ffffff;
}

.ant-divider-vertical {
    margin-right: 7px;
    margin-left: -3px;
    height: 1.4em;
}

/*
.dark-layout .ant-card:hover {
    background-color: #808b96;
} */

.light-layout .ant-modal-header, .light-layout .ant-modal-title {
    background-color: #f37054;
    color: #fff
}

.light-layout .ant-scroll-number-custom-component:hover {
    background-color: #808b96;
}

/*Small Table*/
.light-layout .ant-table-thead {
    background-color: #f37054;
    color: #ffffff;
}

.dark-layout .ant-table-thead {
    background-color: #1B2F41;
    color: #ffffff;
}

.btn-add {
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: initial;
    overflow: hidden;
}
